import axios from "axios";
const tokenizer = process.env.FORM_TOKENIZER_API;
const url = process.env.CONTENTLOAN_API_URL;

const getToken = async (uid) => {
  const data = await axios.get(`${tokenizer}comfama/tokenizer/api/v1/accessToken`,
      {
          params: { 
              uid: uid
          }
      }
  )
  return data
}
const getTokenOverdrive = async (payload) => {
  const data = await axios.post(`${url}/contentloan/api/v1/overdrive-token`, payload)
  return data
}

export { getToken, getTokenOverdrive }
