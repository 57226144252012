import axios from "axios";

export const getCollectionToken = async (uid = "") => {
  const data = await axios.get(
    `${process.env.CONTENTLOAN_API_URL}/contentloan/api/v1/token${
      uid ? `?uid=${uid}` : ""
    }`
  )
  return data.data.data;
}

export const setCollectionToken = async (uid, isLogin = true, initialLoad = false) => {
  const lsDate = localStorage.getItem("thisDate") || new Date().getTime().toString();
  const diffDate = (new Date().getTime() - parseInt(lsDate)) / 1000; // miliseconds
  if (
    (!isLogin && !initialLoad) ||
    (isLogin && !localStorage.getItem("uid") && !initialLoad) || 
    (initialLoad && !localStorage.getItem("collectionToken")) ||
    diffDate >= 86400 // 86400 seconds are one hour
  ) {
    document.body.style.cursor = "wait";
    await getCollectionToken(uid).then(res => {
      localStorage.setItem("collectionToken", res);
      localStorage.setItem("thisDate", new Date().getTime().toString());
      console.log('reload desde el getCollectionToken')
      window.location.reload();
      document.body.style.cursor = "default";
    })
  }
}
