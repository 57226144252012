import useLoginGigya from "../hooks/useLoginGigya";

interface generalInfoInt {
  uid: string;
  interactionType: string;
  url: string;
  date: string;
  sessionId: string;
}

const interestUppercase = {
  "Libros físicos": "LIBRO_FISICO",
  "Libros digitales": "LIBRO_DIGITAL",
  "Revistas": "REVISTA",
  "Películas": "PELICULA",
}

const originFirstChar = {
  "physical": "F",
  "digital": "D"
}

const useMarketingTags = () => {
  const { gigyaAccount } = useLoginGigya();

  const deleteTildes = (value) => {
    value = value.replace(/Á/gi, "A");
    value = value.replace(/É/gi, "E");
    value = value.replace(/Í/gi, "I");
    value = value.replace(/Ó/gi, "O");
    value = value.replace(/Ú/gi, "U");
    value = value.replace(/Ñ/gi, "N");
    return value;
  };

  const switchFunction = (
    interactionType: string, 
    generalInfo: generalInfoInt,
    interactionTags?: Array<string>,
    interactionInterests?: Array<string>,
    productId?: string,
    origin?: string,
    interactionProductCategories?: Array<string>,
  ) => {
    switch (interactionType) {
      case "WEBSITE_SEARCH":
      case "SCHEDULE_DATE":
      case "LIVE_CLASS":
      case "SHOW_ITEM_VIEW":     
        return websiteSearch(generalInfo, interactionTags, interactionInterests,interactionProductCategories);
      case "SHOP_ITEM_VIEW":
      case "PROD_SHARED":
      case "PROD_GET":   
        return productInteraction(generalInfo, productId, origin);
      case "FREE_TEST":
        return simpleInteraction(generalInfo);
  
    }
  }

  const websiteSearch = async (
    generalInfo: generalInfoInt,
    interactionTags: Array<string> = [],
    interactionInterests: Array<string> = [],
    interactionProductCategories: Array<string> = [],
  ) => {
    const tagsList: Array<any> = [], interestsList: Array<any> = [], productCategoryList: Array<any> = [];
    interactionTags.map((tag: string) => {
      if (tag && tag !== "") {
        tagsList.push({
          TagOrigin: "INTERNAL",
          TagType: "SEARCHTERM",
          TagName: tag
        });
      }
    });
    interactionProductCategories.map((pCategory: string) => {
      if (pCategory && pCategory !== "") {
        productCategoryList.push({
            ProductCategoryHierarchy: "comfamaProductCatalog:Online",
            ProductCategory: pCategory,
            IntactnProdCatWeightingFctr: 1,
            InteractionProdCatSentimentVal: 1
        });
      }
    });
    interactionInterests.map((interest: string) => {
      if (interest && interest !== "") {
        interestsList.push({
          ItemOfInterest: interestUppercase[interest] ?
            deleteTildes(interestUppercase[interest]) : deleteTildes(interest),
          InteractionIntrstWeightingFctr: 1,
          InteractionIntrstSentimentVal: 1
        });
      }
    });
    const objectToSend = {
      ...generalInfo,
      interactionTag: tagsList.length ? [...tagsList] : undefined,
      interactionInterest: interestsList.length ? [...interestsList] : undefined,
      interactionProductCategory: productCategoryList.length ? [...productCategoryList] : undefined
    }
    if (tagsList.length > 0 || interestsList.length > 0) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({...objectToSend})
      }
      const req = await fetch(`${process.env.URL_BIBLIOTECAS_SERVICES}/marketing/api/v1/marketing`, requestOptions)
      return req.json();
        // .then(async res => await res.json())
        // .catch(err => console.log(err))
    }
  }

  const productInteraction = async (
    generalInfo: generalInfoInt,
    productId: string = "",
    origin?: string,
  ) => {
    const product = origin ? productId + "_" + originFirstChar[origin] : productId;
    const objectToSend = {
      ...generalInfo,
      interactionProduct: [
        {
          ProductOrigin: "SAP_HYBRIS_PRODUCT",
          Product: product,
          InteractionProdWeightingFactor: 1,
          InteractionProductSentimentVal: 0,
          InteractionProductAmount: "",
          InteractionProductQuantity: "1",
          InteractionProductUnit: "PC",
          ProductRecommendationModelType: "",
          ProductRecommendationScenario: "",
          InteractionProductStatus: "",
          InteractionProductReason: "",
          InteractionSourceItemNumber: null
        }
      ]
    }
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({...objectToSend})
    }
    const req = await fetch(`${process.env.URL_BIBLIOTECAS_SERVICES}/marketing/api/v1/marketing`, requestOptions)
    return req.json();
      // .then(async res => await res.json())
      // .catch(err => console.log(err))
  }

  const getUrlParams = (url: string, params: Array<string>) => {
    const urlElement = new URL(url);
    const urlParams = urlElement.search.split("&");
    const urlToSend = urlElement.origin + urlElement.pathname + urlParams[0]
    let paramsObj = {urlToSend};
    urlParams.map(param => {
      const paramName = param.slice(0, param.indexOf("="));
      if (params.includes(paramName)) paramsObj[paramName] = param.slice(param.indexOf("=") + 1)
    });
    return paramsObj;
  }

  const simpleInteraction = async (
    generalInfo: generalInfoInt,
  ) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({...generalInfo})
    }
    const req = await fetch(`${process.env.URL_BIBLIOTECAS_SERVICES}/marketing/api/v1/marketing`, requestOptions)
     return req.json();
  }
  
  const sendInfo = async (
    interactionType: string, 
    url: string, 
    interactionTags?: Array<string>,
    interactionInterests?: Array<string>,
    productId?: string,
    origin?: string,
    projectId?: string,
    area?: string,
    gygiaInfo?: any,
    interactionProductCategories?: Array<string>,
  ) => {
    const gigyAcc = gygiaInfo || gigyaAccount;
    if (gigyAcc?.UID) {
      const urlParams = getUrlParams(url, ["sap-outbound-id", "utm_campaign"]);
      const isCampaign = urlParams["utm_campaign"];
      const jsDate = new Date();
      const formattedDate = jsDate.getDate() + "/" + (jsDate.getMonth() + 1) + "/" + 
        jsDate.getFullYear() + " " + jsDate.getHours() + ":" + jsDate.getMinutes() +
        ":" + jsDate.getSeconds() + "." + jsDate.getMilliseconds();

      const generalInfo = {
        uid: isCampaign ? urlParams["sap-outbound-id"] : gigyAcc.UID,
        interactionType,
        url: urlParams["urlToSend"] || url,
        date: formattedDate,
        sessionId: gigyAcc.lastLoginTimestamp,
        campaignId: isCampaign ? urlParams["utm_campaign"] : "",
        contactOrigin: isCampaign ? "SAP_TRACKING_ID" : "GIGYA_ID",
        sessionType: isCampaign ? "CUAN_MARKETING_ORCHESTRATION" : "EXT",
        InteractionSourceObject: isCampaign ? gigyAcc.lastLoginTimestamp : "",
        urlCampaign: isCampaign ? url : "",
        contentLinkName: isCampaign ? "Enlace" : "",
        marketingLocation:  projectId || "COMFA_VIRT_0001",
        area: area || "EDUC_CUL"
      };
      await switchFunction(
        interactionType,
        generalInfo,
        interactionTags,
        interactionInterests,
        productId,
        origin,
        interactionProductCategories,
      );
    }
  }

  return { sendInfo }
}

export default useMarketingTags;