import React, { useState, Fragment, useEffect, useContext } from "react";
import { useStaticQuery, graphql } from "gatsby";

import SimpleModal from "../../molecules/simple-modal/simpleModal";
import useLoginGigya from "../../../shared/hooks/useLoginGigya";
import useBrowserMode from "../../../shared/hooks/useBrowserMode";
import GlobalContext from "../../../contexts/globalContext";
import GettingBookCard from "./gettingBookCard";
import Loading from "../../atoms/loading/loading";
import useMarketingTags from "../../../shared/services/useMarketingTags";
import { setCollectionToken } from "../../../services/token";

import "./gettingBook.scss";

interface GettingBookProps {
  digitalCopies: Array<{
    service: string;
    url: string;
    reservationId: string | null;
    isAvailable: boolean;
    isOverdrive: boolean;
    isBorrowed: boolean;
    isOnHold: boolean;
  }>;
  physicalCopies: Array<{
    library: string;
    libraryCode: string;
    totalRecords: number;
    listCopy: Array<{
      holdingId: string;
      callNumber: string;
      location: string;
      locationCode: string;
      barcode: string;
      isAvailable: boolean;
    }>;
  }>;
  image: string;
  title: string;
  author: string;
  isAudiobook: boolean;
  resourceType: string;
  ready: boolean;
  mmsId: string;
  origin: string;
}
 

const GettingBook = (props: GettingBookProps) => {
  const data = useStaticQuery(graphql`
    query QueryGettingBook {
      allContentfulHome(filter: { titulo: { eq: "Home comfama.com" } }) {
        nodes {
          referenciaContenido {
            tipoComponente
            tituloBoton
            tituloGeneral
            descripcion {
              json
            }
            imagenPrincipal {
              file {
                url
              }
            }
            redireccionBoton
          }
        }
      }
    }
  `);

  const {
    digitalCopies = [],
    physicalCopies = [],
    image,
    title,
    author,
    isAudiobook,
    resourceType,
    ready,
    mmsId,
    origin,
  } = props;

  const [tabSelected, setTabSelected] = useState("digital");
  const [openAccordion, setOpenAccordion] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [digitalCopySelected, setDigitalCopySelected] = useState(-1);
  const [modalTitle, setModalTitle] = useState("");
  const [modalImageUrl, setModalImageUrl] = useState("");
  const [modalDesc, setModalDesc] = useState("");
  const [modalBtnText, setModalBtnText] = useState("");
  const [modalBtnFn, setModalBtnFn] = useState("borrowBook");
  const [userUid, setUserUid] = useState<any>(undefined);
  const [gettingBookText, setGettingBookText] = useState("");
  const { login, gigyaAccount } = useLoginGigya();
  const { window } = useBrowserMode();
  const { sendInfo } = useMarketingTags();
  const context = useContext(GlobalContext);

  const contentfulData = data.allContentfulHome.nodes[0].referenciaContenido.find(
    (el) => el.tipoComponente === "ModalConseguirLibro"
  );

  useEffect(() => {
    if (digitalCopies.length === 0 && physicalCopies.length > 0) {
      setTabSelected("physical");
    }
    if (contentfulData) {
      const modalBtnText = contentfulData.tituloBoton.split("/");
      setModalTitle(contentfulData.tituloGeneral);
      setModalImageUrl(contentfulData.imagenPrincipal.file.url);
      setModalDesc(contentfulData.descripcion.json.content[0].content[0].value);
      setModalBtnText(
        isAudiobook ? modalBtnText[1].trim() : modalBtnText[0].trim()
      );
    }
    setGettingBookText(
      resourceType === "audiobook" ? "Conseguir audiolibro" : 
                resourceType === "book" ? "Conseguir libro" : resourceType === "journal" ? "Conseguir revista" : resourceType === "film" ? "Conseguir película" : "Conseguir libro"
    );
  }, [props]);

  // useEffect(() => {
  //   setUserUid(context.gigyaUid?.get);
  //   console.log(userUid)
  //   if (userUid !== undefined) {
  //     // document.body.style.cursor = "wait";
  //     console.log('Entro al 1er useEffect');
  //     // setTimeout(() => {
  //     //   console.log('Entro al 1er timeout - useEffect');
  //     //   location.reload();
  //     // }, 5000);
  //     // location.reload();
  //   }
  // }, [context.gigyaUid?.get]);

  // useEffect(() => {
  //   if (gigyaAccount?.UID) {
  //     setUserUid(gigyaAccount?.UID);
  //     if (userUid !== undefined && userUid !== gigyaAccount.UID) {
  //       // document.body.style.cursor = "wait";
  //       console.log('Entro al 2do useEffect');
  //       // setTimeout(() => {
  //       //   console.log('Entro al 2do timeout - useEffect');
  //       //   location.reload();
  //       // }, 5000);
  //       // location.reload();
  //     }
  //   }
  // }, [gigyaAccount]);

  useEffect(() => {
    if (gigyaAccount?.UID) {
      setUserUid(gigyaAccount?.UID);
      setCollectionToken(gigyaAccount.UID).then(() => {
        localStorage.setItem("uid", gigyaAccount.UID);
      });
      context.gigyaUid?.set(gigyaAccount.UID);
    } else {
      context.gigyaUid?.set("");
    }
  }, [gigyaAccount]);

  const handleTabs = (selection: string) => setTabSelected(selection);

  const handleAccordions = (accordionIndex: number) =>
    openAccordion === accordionIndex
      ? setOpenAccordion(-1)
      : setOpenAccordion(accordionIndex);

  const handleShowModal = (
    show: boolean,
    reloadPage: boolean = false,
    digitalCopyIndex?: number
  ) => {
    if (!userUid) return login();
    setDigitalCopySelected(
      digitalCopyIndex !== undefined ? digitalCopyIndex : -1
    );
    setShowModal(show);
    console.log('entro al reload del handleShowModal', reloadPage)
    if (reloadPage) location.reload();
  };

  const setModalContent = (
    desc: string,
    btnText: string,
    goToUrl: boolean,
    btnFn: string = "",
    title: string = "",
    imageUrl: string = ""
  ) => {
    if (!goToUrl) {
      setModalTitle(title);
      setModalImageUrl(imageUrl);
      setModalDesc(desc);
      setModalBtnText(btnText);
      setModalBtnFn(btnFn);
      setShowModal(true);
    }
  };

  const showModalAfterAction = (
    actionType: string,
    goToUrl: boolean = false
  ) => {
    switch (actionType) {
      case "borrowBook":
        setModalContent(
          `El ${
            isAudiobook ? "audiolibro" : "libro"
          } se ha conseguido exitosamente.`,
          "Aceptar",
          goToUrl
        );
        break;
      case "holdsInsert":
        setModalContent(
          "Has sido añadido a la lista de espera exitosamente.",
          "Aceptar",
          goToUrl
        );
        break;
      case "holdsDelete":
        setModalContent(
          "Has sido eliminado de la lista de espera exitosamente.",
          "Aceptar",
          goToUrl
        );
        break;
      case "returnBook":
        setModalContent(
          `El ${
            isAudiobook ? "audiolibro" : "libro"
          } ha sido devuelto exitosamente.`,
          "Aceptar",
          goToUrl
        );
        break;
    }
  };

  const postOverdrive = (
    actionType: string,
    goToUrl?: boolean,
    copySelected: number = -1,
    read: boolean = false
  ) => {
    if (!userUid) return login();
    document.body.style.cursor = "wait";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "collection": localStorage.getItem("collectionToken") || ""
      },
      body: JSON.stringify({
        reservationId:
          copySelected !== -1
            ? digitalCopies[copySelected].reservationId
            : digitalCopies[digitalCopySelected].reservationId,
        uid: window.localStorage.getItem("uid"),
        contentType: resourceType,
      }),
    };
    const request = fetch(
      `${process.env.CONTENTLOAN_API_URL}/contentloan/api/v1/${actionType}`,
      requestOptions
    );
    request.then(async (res) => {
      const data = await res.json();
      if (actionType === "borrowBook" && copySelected !== -1 && !read) {
        if (data.status === 400) {
          document.body.style.cursor = "default";
          return setModalContent(
            "¡Lo sentimos! Has alcanzado el cupo de préstamos simultáneos en tu cuenta de OverDrive. Para prestar este título, te sugerimos devolver uno de los que tienes actualmente en préstamo.",
            "Aceptar",
            goToUrl || false
          );
        }
        const modalBtnText = contentfulData.tituloBoton.split("/");
        setModalContent(
          contentfulData.descripcion.json.content[0].content[0].value,
          isAudiobook ? modalBtnText[1].trim() : modalBtnText[0].trim(),
          false,
          "borrowBook",
          contentfulData.tituloGeneral,
          contentfulData.imagenPrincipal.file.url
        );
        setDigitalCopySelected(copySelected);
        return (document.body.style.cursor = "default");
      }
      if (goToUrl) window.open(data.data, "_blank");
      if (showModal) {
        setShowModal(false);
        window.open(data.data, "_blank");
        location.reload();
      } else {
        showModalAfterAction(actionType, goToUrl);
        document.body.style.cursor = "default";
      }
    });
  };

  const sendTagMarketing = () => {
    sendInfo("PROD_GET", window.location.href, [], [], mmsId, origin);
  };

  return (
    <section className="o-getting-book" id="conseguirlibro">
      <h2 className="o-getting-book__title">{gettingBookText}</h2>
      {ready ? (
        <>
          <div className="o-getting-book__tabs">
            {physicalCopies.length === 0 && digitalCopies.length === 0 && (
              <p className="o-getting-book__content__card__no-books">
                No se encuentran ejemplares disponibles
              </p>
            )}
            {digitalCopies.length > 0 && (
              <button
                className={`o-getting-book__tabs__button gtmDetalleLibroClicLibroCopiaDigital ${
                  tabSelected === "digital"
                    ? "o-getting-book__tabs__button--active"
                    : ""
                }`}
                onClick={() => handleTabs("digital")}
              >
                Copias digitales
              </button>
            )}
            {physicalCopies.length > 0 && (
              <button
                className={`o-getting-book__tabs__button gtmDetalleLibroClicLibroCopiaFisica ${
                  tabSelected === "physical"
                    ? "o-getting-book__tabs__button--active"
                    : ""
                }`}
                onClick={() => handleTabs("physical")}
              >
                Copias físicas
              </button>
            )}
          </div>
          <div className="o-getting-book__content">
            {tabSelected === "digital" &&
              digitalCopies.length > 0 &&
              digitalCopies.map((copy, index) => (
                <Fragment key={index}>
                  <GettingBookCard
                    {...copy}
                    image={image}
                    title={title}
                    author={author}
                    type={"digital"}
                    index={index}
                    postOverdrive={(
                      actionType,
                      goToUrl = false,
                      cardIndex = index,
                      read
                    ) => postOverdrive(actionType, goToUrl, cardIndex, read)}
                    resourceType={resourceType}
                    sendTagMarketing={sendTagMarketing}
                  />
                </Fragment>
              ))}
            {tabSelected === "physical" &&
              physicalCopies.length > 0 &&
              physicalCopies.map(
                (location, index) =>
                  location.totalRecords > 0 && (
                    <Fragment key={index}>
                      <div
                        className="o-getting-book__content__accordeon"
                        onClick={() => handleAccordions(index)}
                      >
                        <p className="o-getting-book__content__accordeon__title">
                          {`${location.library} (${location.totalRecords})`}
                        </p>
                        <div
                          className={`o-getting-book__content__accordeon__icon
                        ${
                          openAccordion === index ? "icon-minus" : "icon-plus"
                        }`}
                        ></div>
                      </div>
                      <div
                        className={`o-getting-book__content__accordeon__content
                      ${
                        index !== openAccordion
                          ? "o-getting-book__content__accordeon__content--hide"
                          : ""
                      }`}
                      >
                        {location.listCopy.map((copy, copyIndex) => (
                          <Fragment key={copyIndex}>
                            <GettingBookCard
                              {...copy}
                              image={image}
                              title={title}
                              author={author}
                              type={"physical"}
                              getBookUrl={contentfulData.redireccionBoton}
                              resourceType={resourceType}
                              sendTagMarketing={sendTagMarketing}
                            />
                          </Fragment>
                        ))}
                      </div>
                    </Fragment>
                  )
              )}
          </div>
          <SimpleModal
            open={showModal}
            onClose={() => handleShowModal(false, true)}
            title={modalTitle}
            imageUrl={modalImageUrl}
            description={modalDesc}
            btnText={modalBtnText}
            onBtnClick={
              modalBtnFn !== ""
                ? () => postOverdrive(modalBtnFn)
                : () => handleShowModal(false, true)
            }
          />
        </>
      ) : (
        <Loading />
      )}
    </section>
  );
};

export default GettingBook;
