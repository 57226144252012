import React, { useState, useEffect } from "react";

import { getLanguageValue } from "../../../utils/languagesUtilities";
import { getFormatValue, getOriginValue } from "../../../utils/bookFormatsUtilities";
import { firstUpperOnly } from "../../../shared/utils/stringUtilities";

import "./bookDescription.scss";

interface DescriptionObj {
  descripcion: string;
}

interface BookDescriptionProps {
  description: string;
  physicDescription: string;
  publicationDate: string;
  language: string;
  collaboratorsList: Array<{
    nombre: string;
    responsabilidad: string;
  }>;
  subjectsList: Array<DescriptionObj>;
  notes: Array<DescriptionObj>;
  type: string;
  origin: string;
  durations: Array<string>;
}

const BookDescription = (props: BookDescriptionProps) => {
  const [subjects, setSubjects] = useState<Array<string>>([]);
  const [infoRightSide, setInfoRightSide] = useState<any>([]);

  useEffect(() => {
    if (props.subjectsList) {
      let auxSubjects = props.subjectsList.map(subject => (
        subject.descripcion
      ));
      setSubjects(auxSubjects);
    }
    setInfoRightSide(getInfoFromProps);
  }, [props.subjectsList]);

  const getInfoFromProps = () => {
    const pubDate = props.publicationDate ? 
      getInfoObj("Fecha de publicación", props.publicationDate) : "";
    const extraAuthors = props.collaboratorsList.length > 0 ? 
      getInfoObj("Otros autores", getAuthors(props.collaboratorsList)) : "";
    const format = props.type ? 
      getInfoObj("Formatos", props.type === "book" ? 
        `Libro ${getOriginValue(props.origin)}` : getFormatValue(props.type)) : "";
    const language = props.language ? 
      getInfoObj("Idiomas", firstUpperOnly(getLanguageValue(props.language))) : "";
    const physicDesc = props.physicDescription ?
      getInfoObj("Descripción física", props.physicDescription) : "";
    const notes = props.notes.length > 0 ?
      getInfoObj("Notas", getNotes(props.notes)) : "";
    const durations = props.durations.length > 0 ?
      getInfoObj("Duración", props.durations.join(" - ")) : "";

    const auxArr = [
      pubDate, extraAuthors, format, language, physicDesc, durations, notes
    ];
    return auxArr.filter(element => element);
  }

  const getAuthors = (authorsArray) => {
    const auxAuthors = authorsArray.map(author => (
      author.nombre.replace(/-/g, "").trim()
    ))
    return auxAuthors.join(" - ");
  }

  const getNotes = (notesArray) => {
    const auxNotes = notesArray.map(note => (
      note.descripcion.trim()
    ))
    return auxNotes.join(". ");
  }

  const getInfoObj = (title, content) => ({ title, content });

  return (
    <div className="o-book-desc">
      <h2 className="o-book-desc__title">Descripción</h2>
      <div className="o-book-desc__columns">
        <div className="o-book-desc__left">
          <p className="o-book-desc__left__description">
            {props.description || "Muy pronto te contaremos más sobre este título."}
          </p>
          {subjects.length > 0 &&
            <>
            <p className="o-book-desc__left__subjects-title">
              Materias
            </p>
            <ul className="o-book-desc__left__subjects-items">
              {subjects.slice(0, 10).map((subject, index) => 
                <li className="o-book-desc__left__subjects-items__item" key={index}>
                  {subject}
                </li>
              )}
            </ul>
            </>
          }
        </div>
        <div className="o-book-desc__vertical-divider"></div>
        <div className="o-book-desc__right">
          {infoRightSide.map((info, index) => (
            <div className="o-book-desc__right__item" key={index}>
              <p className="o-book-desc__right__item__title">
                {info.title}
              </p>
              <p className="o-book-desc__right__item__content">
                {info.content}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default BookDescription;