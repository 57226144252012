const formats = {
  book: "Libro físico - Libro digital",
  audiobook: "Audiolibro",
  podcast: "Podcast",
  journal: "Revista",
  film: "Película",
  document: "Documento"
}

const origins = {
  physical: "Físico",
  digital: "Digital"
}

export const getFormatValue = (key: string) => formats[key];
export const getOriginValue = (key: string) => origins[key];