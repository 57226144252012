import React, { useState } from "react";

import DefaultImage from "../../../assets/images/defaultLibro.jpg";
import { normalizeTwoWords } from "../../../shared/utils/stringUtilities";

interface GettingBookCardProps {
  image: string;
  title: string;
  author?: string;
  type: string;
  location?: string;
  callNumber?: string;
  service?: string;
  isAvailable: boolean;
  isOverdrive?: boolean;
  isOnHold?: boolean;
  isBorrowed?: boolean;
  getBookUrl?: string;
  openModal?: Function;
  url?: string;
  postOverdrive?: Function;
  barcode?: string;
  index?: number;
  resourceType: string;
  sendTagMarketing: Function;
  availabilityType?: string;
  copiesAvailable?: number;
  copiesOwned?: number;
}

const GettingBookCard = (props: GettingBookCardProps) => {
  const [imgError, setimgError] = useState(false);
  const {resourceType} = props;
  console.log(props)

  const handleErroImage = () => {
    setimgError(true);
  };

  const getBook = () => {
    props.sendTagMarketing();
    props.postOverdrive
      ? props.postOverdrive("borrowBook", false, props.index)
      : false;
  };

  const getNameBtn = (act = "Conseguir") => (resourceType === "audiobook" ? `${act} audiolibro` : 
  resourceType === "book" ? `${act} libro` : resourceType === "journal" ? `${act} revista` : resourceType === "film" ? `${act} película` : `${act} libro`)

  return (
    <div className="o-getting-book__content__card">
      <div className="o-getting-book__content__card__upside">
        <div className="o-getting-book__content__card__upside__book-info">
          <img
            className="o-getting-book__content__card__image"
            src={
              props.image
                ? imgError
                  ? DefaultImage
                  : props.image
                : DefaultImage
            }
            alt={props.title || "Imagen conseguir libro"}
            onError={handleErroImage}
          />
          <div className="o-getting-book__content__card__texts">
            <h3 className="o-getting-book__content__card__texts__title">
              {props.title}
            </h3>
            <h4 className="o-getting-book__content__card__texts__author">
              {props.author}
            </h4>
            <p className="o-getting-book__content__card__texts__service">
              {props.type === "physical"
                ? `${props.location} ${props.callNumber}`
                : props.service}
            </p>
          </div>
        </div>
        {props.type === "physical" && props.isAvailable && (
          <a
            className={`o-getting-book__content__card__upside__right-button gtmDetalleLibroClicLibroConseguirLibro${normalizeTwoWords(
              props.title
            )}Fisico`}
            href={props.getBookUrl}
            target="_blank"
            rel="noreferrer"
            onClick={() => props.sendTagMarketing()}
          >
            {getNameBtn()}
          </a>
        )}
        {props.type === "digital" && props.isAvailable && !props.isOverdrive && (
          <a
            className={
              "o-getting-book__content__card__upside__right-button " +
              `gtmDetalleLibroClicLibroConseguirLibro${normalizeTwoWords(
                props.title
              )}Digital`
            }
            href={props.url}
            target="_blank"
            rel="noreferrer"
            onClick={() => props.sendTagMarketing()}
          >
            {getNameBtn()}
          </a>
        )}
        {props.type === "digital" &&
          props.isAvailable &&
          props.isOverdrive &&
          !props.isBorrowed && (
            <button
              className={`o-getting-book__content__card__upside__right-button gtmDetalleLibroClicLibroConseguirLibro${normalizeTwoWords(
                props.title
              )}Digital`}
              onClick={() => getBook()}
            >
              {getNameBtn()}
            </button>
          )}
        {props.type === "digital" &&
          props.isOverdrive &&
          !props.isBorrowed &&
          !props.isAvailable &&
          props.isOnHold && (
            <button
              className={`o-getting-book__content__card__upside__right-button gtmDetalleLibroClicLibroQuitarEspera${normalizeTwoWords(
                props.title
              )}`}
              onClick={() =>
                props.postOverdrive ? props.postOverdrive("holdsDelete") : false
              }
            >
              Quitar de la lista de espera
            </button>
          )}
        {props.type === "digital" &&
          !props.isAvailable &&
          props.isOverdrive &&
          !props.isOnHold &&
          !props.isBorrowed && (
            <button
              className={`o-getting-book__content__card__upside__right-button gtmDetalleLibroClicLibroAnadirEspera${normalizeTwoWords(
                props.title
              )}`}
              onClick={() =>
                props.postOverdrive ? props.postOverdrive("holdsInsert") : false
              }
            >
              Añadir a la lista de espera
            </button>
          )}
        {props.type === "digital" && props.isOverdrive && props.isBorrowed && (
          <div className="o-getting-book__content__card__upside__right-side">
            <button
              className={`o-getting-book__content__card__upside__right-button gtmDetalleLibroClicLibroDevolverLibro${normalizeTwoWords(
                props.title
              )}`}
              onClick={() =>
                props.postOverdrive ? props.postOverdrive("returnBook") : false
              }
            >
              {getNameBtn("Devolver")}
            </button>
            <button
              className={`o-getting-book__content__card__upside__right-button gtmDetalleLibroClicLibro${
                resourceType === "audiobook" ? `EscucharAudiolibro` : 
                resourceType === "book" ? `LeerLibro` : resourceType === "journal" ? `LeerRevista` : resourceType === "film" ? `VerPelícula` : `LeerLibro`
              }Libro${normalizeTwoWords(props.title)}`}
              onClick={() =>
                props.postOverdrive
                  ? props.postOverdrive("borrowBook", true, props.index, true)
                  : false
              }
            >
              {resourceType === "audiobook" ? "Escuchar audiolibro" : resourceType === "film" ? "Ver película" :  getNameBtn("Leer")}
            </button>
            {props.isOnHold && (
              <button
                className={`o-getting-book__content__card__upside__right-button gtmDetalleLibroClicLibroQuitarEspera${normalizeTwoWords(
                  props.title
                )}`}
                onClick={() =>
                  props.postOverdrive
                    ? props.postOverdrive("holdsDelete")
                    : false
                }
              >
                Quitar de la lista de espera
              </button>
            )}
          </div>
        )}
      </div>
      <div className="o-getting-book__content__card__divider"></div>
      <div className="o-getting-book__content__card__downside">
        {props.isAvailable ? (
          <>
            <p className="o-getting-book__content__card__downside__availability">
              Disponible
            </p>
            {props.isOverdrive && (
              <>
              <p className="o-getting-book__content__card__downside__available-quant">
                {props.availabilityType === "Normal" || props.availabilityType === "AlwaysAvailable" &&  
                  <i className="icon-copy1"></i>
                }
                {props.availabilityType === "Normal" 
                  ? `${props.copiesAvailable} de ${props.copiesOwned} copia(s) disponible(s)`
                  : props.availabilityType === "AlwaysAvailable" 
                    ? "Siempre disponible"
                    : ""
                }
              </p>
              <a 
                className="o-getting-book__content__card__downside__support-od"
                href="https://help.overdrive.com/en-us/home.htm"
                rel="noreferrer"
                target="_blank"
              >
                Soporte de OverDrive
              </a>
              </>
            )}
            {props.barcode && (
              <p className="o-getting-book__content__card__downside__text">
                Código de barras: {props.barcode}
              </p>
            )}
          </>
        ) : (
          <>
            <p
              className="o-getting-book__content__card__downside__availability
              o-getting-book__content__card__downside__availability--not-available"
            >
              No disponible
            </p>
            <p className="o-getting-book__content__card__downside__text">
              {props.isBorrowed ? "" : "En préstamo por otro usuario. "}
              {props.barcode ? `Código de barras: ${props.barcode}` : ""}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default GettingBookCard;
