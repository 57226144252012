import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";

import Breadcrumb from "../../atoms/breadcrumb/breadcrumb";
import Loading from "../../atoms/loading/loading";
import BookDescription from "../book-description/bookDescription";
import HeadboardBookDetail from "../headboard-book-detail/headboardBookDetail";
import GettingBook from "../getting-book/gettingBook";
import AuthorSection from "../author-section/authorSection";
import useBrowserMode from "../../../shared/hooks/useBrowserMode";
import useMarketingTags from "../../../shared/services/useMarketingTags";

import "./bookDetail.scss";

interface DescriptionObj {
  descripcion: string;
}

interface BookDetailProps {
  autor: {
    nombre: string;
    responsabilidad: string | null;
  };
  caratula: string;
  descripcion: string;
  descripcionFisica: string;
  fechaPublicacion: string;
  fragmento: string | null;
  origenContenido: string;
  idioma: string;
  isbn: string;
  listColaborador: Array<{
    nombre: string;
    responsabilidad: string;
  }>;
  listEmocion: Array<DescriptionObj>;
  listMateria: Array<DescriptionObj>;
  listSerie: Array<DescriptionObj>;
  mmsid: string;
  notas: Array<DescriptionObj>;
  signaturaTopografica: string;
  subtitulo: string | null;
  tipoRecurso: string;
  titulo: string;
  listDuracion: Array<string>;
}

const DefaultBookDetails = {
  autor: { nombre: "", responsabilidad: "" },
  caratula: "",
  descripcion: "",
  descripcionFisica: "",
  fechaPublicacion: "",
  fragmento: "",
  origenContenido: "",
  idioma: "",
  isbn: "",
  listColaborador: [],
  listEmocion: [],
  listMateria: [],
  listSerie: [],
  mmsid: "",
  notas: [],
  signaturaTopografica: "",
  subtitulo: "",
  tipoRecurso: "",
  titulo: "",
  listDuracion: []
};

const DefaultGettingBook = {
  digitalCopies: [],
  physicalCopies: [],
};

const BookDetail = () => {
  const data = useStaticQuery(graphql`
    query QueryAuthorsBookDetail {
      allContentfulHome(filter: { titulo: { eq: "Home comfama.com" } }) {
        nodes {
          referenciaAutores {
            tituloGeneral
            imagenPrincipal {
              file {
                url
                fileName
              }
            }
            descripcion {
              json
            }
          }
        }
      }
    }
  `);

  const [bookData, setBookData] = useState<BookDetailProps>(DefaultBookDetails);
  const [gettingBookData, setGetiingBookData] = useState(DefaultGettingBook);
  const [isLoading, setIsLoading] = useState(true);
  const [resourceType, setResourceType] = useState("");
  const [readyGettingBook, setReadyGettingBook] = useState(false);
  const [authorData, setAuthorData] = useState<any>({});
  const { window } = useBrowserMode();
  const { sendInfo } = useMarketingTags();
  
  const authorsContentful = data.allContentfulHome.nodes[0].referenciaAutores || [];
  const userUid = window.localStorage && window.localStorage.getItem("uid");

  useEffect(() => {
    searchByUrlParams();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      let url = new URL(window.location.href);
      let jsonUrlParams = getJsonFromUrl(url.search);
      sendInfo(
        "SHOP_ITEM_VIEW",
        window.location.href,
        [],
        [],
        jsonUrlParams.book
      );
    }
  }, [isLoading]);

  const searchByUrlParams = () => {
    let url = new URL(window.location.href);
    let jsonUrlParams = getJsonFromUrl(url.search);
    if (jsonUrlParams) {
      getBookDetails(jsonUrlParams.book);
    }
  };

  const getJsonFromUrl = (url: string) => {
    let query = url.substr(1);
    let result: { book: string } = {
      book: "",
    };
    query.split("&").forEach(function (part) {
      let item = part.split("=");
      result[item[0]] =
        decodeURIComponent(item[1]) === "null"
          ? null
          : decodeURIComponent(item[1]);
    });
    return result;
  };

  const getBookDetails = (bookMmsId) => {
    const request = fetch(
      `${process.env.CONTENTLOAN_API_URL}/contentloan/api/v1/detail?mmsId=${bookMmsId}`
    );
    request.then(async (res) => {
      const data = await res.json();
      console.log(data)
      if (data.status === 500) return window.location.replace("/404");
      if (data.data.autor) {
        let authorContentful = authorsContentful.find(author => (
          author.tituloGeneral === data.data.autor.nombre
        ));
        if (authorContentful) {
          fetch(
            `${process.env.CONTENTLOAN_API_URL}/search/api/v1/filter?limit=8&word=${
              data.data.autor.nombre}&contentType=book&page=0`
          ).then(async (res) => {
            const jsonRes = await res.json();
            const booksArr: any = [];
            if (jsonRes.data.filter[0]) {
              jsonRes.data.filter.map((book: any) => {
                if (book.mmsId !== bookMmsId) booksArr.push(book)
              })
            }
            authorContentful.books = [...booksArr];
            setAuthorData(authorContentful);
            setIsLoading(false);
          });
        }
      }
      let url = new URL(window.location.href);
      let jsonUrlParams = getJsonFromUrl(url.search);
      if (jsonUrlParams) {
        getGetBookDetails(jsonUrlParams.book);
      }
      setBookData(data.data);
      setResourceType(data.data.tipoRecurso);
      setIsLoading(false);
    });
  };

  const getGetBookDetails = (bookMmsId) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "collection": localStorage.getItem("collectionToken") || ""
      },
      body: JSON.stringify({
        uid: userUid,
        mmsid: bookMmsId,
      }),
    };
    const requestGetBook = fetch(
      `${process.env.CONTENTLOAN_API_URL}/contentloan/api/v1/copies`,
      options
    );
    requestGetBook.then(async (res) => {
      const data = await res.json();
      if (data.status === 200) {
        setGetiingBookData(data.data);
        setReadyGettingBook(true);
      } else {
        console.log(data.status)
      }
    });
  };

  return (
    <div className="o-book-detail">
      {!isLoading ? (
        <>
          <Breadcrumb
            content={`Inicio / Comfama Mentora / Lo que leemos / ${bookData.titulo}`}
          />
          <HeadboardBookDetail
            author={bookData.autor.nombre || ""}
            image={bookData.caratula || ""}
            title={bookData.titulo || ""}
            fragment={bookData.fragmento || ""}
            courceContent={bookData.origenContenido || ""}
            typeResource={resourceType}
            mmsId={bookData.mmsid}
          />
          <BookDescription
            description={bookData.descripcion}
            physicDescription={bookData.descripcionFisica}
            publicationDate={bookData.fechaPublicacion}
            language={bookData.idioma}
            collaboratorsList={bookData.listColaborador}
            subjectsList={bookData.listMateria}
            notes={bookData.notas}
            type={bookData.tipoRecurso}
            origin={bookData.origenContenido}
            durations={bookData.listDuracion || []}
          />
          {authorData.tituloGeneral && 
            <AuthorSection 
              tituloGeneral="Autor"
              autoresArticulo={[authorData.tituloGeneral]}
              imagenPrincipal={authorData.imagenPrincipal}
              descripcion={authorData.descripcion}
              books={authorData.books}
              tituloBoton="Otros libros del autor"
              redireccionBoton="Ver todo"
            />
          }
          <GettingBook
            {...gettingBookData}
            image={bookData.caratula}
            title={bookData.titulo}
            author={bookData.autor.nombre}
            isAudiobook={resourceType === "audiobook"}
            resourceType={resourceType}
            ready={readyGettingBook}
            mmsId={bookData.mmsid}
            origin={bookData.origenContenido}
          />
        </>
      ) : (
        <>
          <Loading />
        </>
      )}
    </div>
  );
};

export default BookDetail;
