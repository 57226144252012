import React, { useEffect, useState } from "react";
import { withPrefix } from "gatsby";
import ShareModal from "../../molecules/share-modal/shareModal";
import useBrowserMode from "../../../shared/hooks/useBrowserMode";
import useMarketingTags from "../../../shared/services/useMarketingTags";
import { normalizeTwoWords, formatImageAlt } from "../../../shared/utils/stringUtilities";
import { getTokenOverdrive } from "../../../services/tokenizer";
import DefaultImage from "../../../assets/images/defaultLibro.jpg";
import useLoginGigya from "../../../shared/hooks/useLoginGigya";

import "./cardBook.scss";

interface BookInterface {
  book: {
    titulo: string;
    origenContenido: string;
    autor: string;
    image: {
      title: string;
      file: {
        fileName: string;
        url: string;
      };
    };
    mmsId: string;
    tipoContenido: string;
    reservationId: string | null;
    isOverdrive: boolean;
  };
  gtmClass: string;
}

const CardBook = (props: BookInterface) => {
  const { window } = useBrowserMode();
  const { book } = props;
  const { sendInfo } = useMarketingTags();
  const { gigyaAccount } = useLoginGigya();
  const [ available, setAvailable ] = useState<any>(false)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [imgError, setimgError] = useState(false);
  // const [jwtToken, setJwtToken] = useState("");
  const [count, setCount] = useState(0)

  const toggleModal = () => {
    if (!isModalVisible) {
      sendInfo(
        "PROD_SHARED",
        window.location.href,
        [],
        [],
        book.mmsId
      );
    }
    setIsModalVisible(!isModalVisible);
  };

  const handleRedirect = (book) => {
    window.location.assign(withPrefix(`/libros/detalle/?book=${book}`));
  };

  const handleErroImage = () => {
    setimgError(true)
  };

  // const getJwt = async () => {
  //   if (localStorage?.getItem("uid")) {
  //     const req = await getToken(localStorage?.getItem("uid"))
  //     if (req.data.status === 200 && req.data.data.accessToken) {
  //       localStorage.setItem('uidToken', req.data.data.accessToken)
  //       setJwtToken(req.data.data.accessToken)
  //     } else {
  //       // console.log('Error al obtener el uidToken')
  //     }
  //   }
  // }

  const handleAvailable = async () => {
    // console.log("estado", jwtToken)
    if (book.isOverdrive) {
      const options = {
        method: "POST",
        headers: {
          "collection": localStorage.getItem("collectionToken") || "",
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({"overdriveToken": "" }),
        //body: JSON.stringify({"overdriveToken": localStorage?.getItem("overdriveToken") !== null ? localStorage?.getItem("overdriveToken") : "" }), 
      }
      let request = await fetch(`${
          process.env.CONTENTLOAN_API_URL
        }/contentloan/api/v1/availability?reservationId=${
          book.reservationId
        }${localStorage?.getItem("uid") ? "&uid=" + localStorage?.getItem("uid") : ""}`,
        options)
      let data  = await request.json();
      setAvailable(data.data);
      if (data.status === 400) {
        setTimeout(() => {
          handleAvailable();
        }, 3000);
      } else {
        setAvailable(data.data);
      }
    }
  }

  const getOverdriveToken = async (token) => {
    const data = await getTokenOverdrive(token || "")
    localStorage.setItem('overdriveToken', data.data.data.accessToken )
    setCount(count+1)
    console.log("overDriveToken:" + count + data.data.data.accessToken)
  }

  useEffect(() => {
    window.addEventListener("load", () => {});
    //getJwt();
    setTimeout(() => {
      handleAvailable();
    }, 1000);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      handleAvailable();
    }, 1000);
  }, [gigyaAccount])

  useEffect(() => {
    setTimeout(() => {
      if (gigyaAccount?.UID !== undefined) {
        console.log("Gigya Login Ok");
        const payload = {
          "jwt" : localStorage?.getItem('uidToken')
        }
        if (!localStorage.getItem('overdriveToken')) {
          console.log("Token OverDrive con Gigya");
          getOverdriveToken(payload);
        }
      } else {
        const payload = {
          "jwt": ""
        }
        if (!localStorage.getItem('overdriveToken')) {
          console.log("Token OverDrive Sin Gigya");
          getOverdriveToken(payload);
        }
      }
    }, 1000);
}, [gigyaAccount]);

  return (
    <div className="a-card">
      {book &&
      <>
        {
          book.isOverdrive && (
            <div className='a-card__overdrive'>
              <div 
                className={`a-card__overdrive-content ${
                    available ? "a-card__overdrive-content--available" : ""
                  }`
                }
              >
                <p 
                  className={`a-card__overdrive-content__text ${
                      available ? "a-card__overdrive-content__text--available" : ""
                    }`
                  }
                >
                  {available ? 'Disponible': 'Lista de espera'}
                </p>
              </div>
            </div>
          )
        }
        <div className="a-card-content">
          <div className="a-card-content-img" onClick={() => handleRedirect(book.mmsId)}>
            <img
              src={book.image ? (imgError ? DefaultImage: book.image.file.url) : DefaultImage}
              alt={book.image ? 
                book.image.title ? formatImageAlt(book.image.title) : book.titulo
                :
                "Libro comfama"}
              onError={handleErroImage}
              className={`gtm${normalizeTwoWords(props.gtmClass)}Lectura${normalizeTwoWords(book.titulo)}`}
            />
            {book.tipoContenido && book.tipoContenido === "audiobook" && 
              <div className={`a-card-content-img-type ${
                  book.isOverdrive ? ' is-overdrive' : ''} ${book.isOverdrive}`
                }
              >
                <div className="content-icon">
                  <i className={"icon icon-headphones"} />
                </div>
              </div>
            }
            {book.tipoContenido !== "audiobook" && book.origenContenido && (
              <div className={`a-card-content-img-type ${
                  book.isOverdrive ? ' is-overdrive' : ''} ${book.isOverdrive}`
                }
              >
                {book.origenContenido.includes("digital") && (
                  <div className="content-icon">
                    <i className={"icon icon-mobile-alt"} />
                  </div>
                )}
                {book.origenContenido.includes("physical") && (
                  <div className="content-icon">
                    <i className={"icon icon-book"} />
                  </div>
                )}
              </div>
            )}
          </div>
          <div 
            className="a-card-content__title-container"
            onClick={() => handleRedirect(book.mmsId)}
          >
            <h3 className="a-card-content-title hide-on-mobile">{book.titulo}</h3>
            <h3 className="a-card-content-title show-on-mobile">{book.titulo}</h3>
          </div>
          <a
            className="a-card-content-author"
            href={withPrefix(`/resultados-busqueda/?search=${book.autor}&contentType=Todos`)}
          >
            {book.autor}
          </a>
          <span className="a-card-content-separator" />
          <div className="a-card-content-menu">
            <i
              className={`icon icon-share gtm${normalizeTwoWords(props.gtmClass)}Lectura${normalizeTwoWords(book.titulo)}Compartir`}
              onClick={(e) => {
                toggleModal();
                e.stopPropagation();
              }}
            />
          </div>
        </div>
        <ShareModal open={isModalVisible} onClose={toggleModal} />
      </>
      }
    </div>
  );
};

export default CardBook;