import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { withPrefix } from "gatsby";

import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";
import CardBook from "../../atoms/card-book/cardBook";
import Loading from "../../atoms/loading/loading";
import { formatImageAlt } from "../../../shared/utils/stringUtilities";

import "./authorSection.scss";

interface AuthorSectionProps {
  tituloGeneral: string;
  imagenPrincipal: {
    title: string;
    file: {
      url: string;
      fileName: string;
    };
    fluid: any;
  };
  descripcion: { json: any };
  autoresArticulo: Array<string>;
  tituloBoton?: string;
  referenciaPlantillaContenido?: any;
  redireccionBoton?: string;
  books: Array<any>;
}

const AuthorSection = (props: ReferenciaContenido | AuthorSectionProps) => {
  const {
    tituloGeneral,
    imagenPrincipal,
    autoresArticulo,
    descripcion,
    tituloBoton,
    referenciaPlantillaContenido,
    redireccionBoton,
  } = props;

  const [books, setBooks] = useState([]);

  useEffect(() => {
    if (referenciaPlantillaContenido) {
      getBooks();
    } else if (props.books) {
      setBooks(props.books);
    }
  }, [props]);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          centerMode: false,
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 780,
        settings: {
          centerMode: false,
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 580,
        settings: {
          centerMode: false,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 425,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getBooks = async () => {
    const books: any = [];
    for (const book of referenciaPlantillaContenido) {
      const request = await fetch(
        `${process.env.CONTENTLOAN_API_URL}/contentloan/api/v1/detail?mmsId=${book.redireccionBoton}`
      );
      let bookJson = await request.json();
      bookJson = bookJson.data;
      bookJson.image = {
        file: {
          url: bookJson.caratula,
        },
        title: bookJson.titulo,
      };
      bookJson.tipoContenido = bookJson.tipoRecurso;
      bookJson.autor = bookJson.autor.nombre;
      books.push(bookJson);
    }
    setBooks(books);
  };

  return (
    <section className="o-author-section">
      <h2 className="o-author-section__title">{tituloGeneral}</h2>
      <div className="o-author-section__author-info">
        <img
          className="o-author-section__author-info__photo"
          src={imagenPrincipal.file.url}
          alt={imagenPrincipal.title ? formatImageAlt(imagenPrincipal.title) : "Foto autor"}
        />
        <div className="o-author-section__author-info__texts">
          <h3 className="o-author-section__author-info__texts__name">
            {autoresArticulo[0]}
          </h3>
          <p className="o-author-section__author-info__texts__desc">
            {descripcion.json.content[0].content[0].value}
          </p>
        </div>
      </div>
      {books.length > 0 && (
        <>
          <div className="o-author-section__divider"></div>
          <div className="o-author-section__books">
            <div className="o-author-section__books__title-section">
              <h3 className="o-author-section__books__title">{tituloBoton}</h3>
              {books.length > 5 && (
                <a
                  className="o-author-section__books__see-all"
                  href={withPrefix(
                    `/resultados-busqueda/?search=${autoresArticulo[0]}&contentType=Todos`
                  )}
                  target="_self"
                >
                  {redireccionBoton}
                </a>
              )}
            </div>
            <div
              className={`o-author-section__books__books-section hide-on-mobile ${
                books.length < 5
                  ? "o-author-section__books__books-section--less"
                  : ""
              }`}
            >
              {books.length > 0 ? (
                books
                  .slice(0, 5)
                  .map((book, idx) => (
                    <CardBook key={idx} book={book} gtmClass="Seccion Autor" />
                  ))
              ) : (
                <Loading />
              )}
            </div>
            <div
              className={`o-author-section__books__books-section show-on-mobile ${
                books.length < 5
                  ? "o-author-section__books__books-section--less"
                  : ""
              }`}
            >
              {books.length > 0 ? (
                <Slider {...settings} className="o-slider__carrusel">
                  {books.slice(0, 5).map((book, idx) => (
                    <CardBook key={idx} book={book} gtmClass="Seccion Autor" />
                  ))}
                </Slider>
              ) : (
                <Loading />
              )}
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default AuthorSection;
