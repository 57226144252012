import React from "react";
import Layout from "../../components/organisms/layout/layout";
import SEO from "../../shared/seo";
import BookDetail from "../../components/organisms/book-detail/bookDetail";

const BookDetailPage = () => {
  return (
    <Layout hideHeaderDesktop={true} hideHeaderMobile={true} isNotRoot={true}>
      <SEO title="Libros Comfama" lang="es" description=" "/>
      <BookDetail />
    </Layout>
  );
};

export default BookDetailPage;