const languages = {
  afr: "afrikaans",
  alb: "albanés",
  ger: "alemán",
  amh: "amhárico",
  ara: "árabe",
  arg: "aragonés",
  arw: "arahuaco",
  arc: "arameo",
  arm: "armenio",
  aym: "aimara",
  aze: "azerbaiyano",
  ben: "bengalí",
  bel: "bielorruso",
  bur: "birmano",
  bos: "bosnio",
  bre: "bretón",
  bul: "búlgaro",
  cat: "catalán; valenciano",
  che: "checheno",
  cze: "checo",
  chb: "chibcha, muisca",
  chi: "chino (cantonés, mandarín)",
  kor: "coreano",
  cor: "córnico",
  cos: "corso",
  hat: "creóle (haitiano)",
  hrv: "croata",
  dan: "danés",
  chu: "eslavo eclesiástico antiguo",
  slo: "eslovaco",
  slv: "esloveno",
  spa: "español (castellano)",
  epo: "esperanto",
  est: "estonio",
  baq: "euskera (vasco)",
  fin: "finés (finlandés)",
  fre: "francés",
  fur: "friulano",
  gla: "gaélico escocés",
  wel: "galés",
  glg: "gallego",
  geo: "georgiano",
  grc: "griego antiguo (clásico) (hasta 1453)",
  gre: "griego moderno (después de 1453)",
  kal: "groenlandés",
  grn: "guaraní",
  heb: "hebreo",
  hin: "hindi",
  hun: "húngaro",
  ind: "indonesio",
  eng: "inglés",
  ang: "inglés antiguo (ca. 450-1100)",
  gle: "irlandés",
  ice: "islandés",
  ita: "italiano",
  jpn: "japonés",
  jpr: "judeo-persa",
  jrb: "judeoárabe",
  cab: "kariña, karibe",
  kaz: "kazajo",
  kur: "kurdo",
  lat: "latín",
  lav: "letón",
  lim: "limburgués",
  lit: "lituano",
  ltz: "luxemburgués",
  mac: "macedonio",
  mal: "malabar, malayalam",
  may: "malayo",
  mlt: "maltés",
  mao: "maorí",
  arn: "mapuche (araucano)",
  mon: "mongol",
  nah: "náhuatl",
  nap: "napolitano (italiano, sur)",
  dut: "neerlandés (holandés); flamenco",
  nep: "nepalés",
  nno: "noruego (nynorsk)",
  nor: "noruego",
  nob: "noruego bokmal",
  oci: "occitano (después de 1500)",
  pus: "pastún",
  per: "persa; farsi",
  pol: "polaco",
  por: "portugués",
  que: "quechua (quichua)",
  roh: "romanche (retorrománico)",
  rom: "romaní (caló)",
  rum: "rumano",
  rus: "ruso",
  sai: "aborígenes sudamericanos",
  san: "sánscrito",
  srd: "sardo",
  srp: "serbio",
  scn: "siciliano",
  snd: "sindhi",
  syr: "siríaco",
  swe: "sueco",
  tah: "tahitiano",
  tha: "thai",
  tib: "tibetano",
  tur: "turco",
  tuk: "turcomano",
  ukr: "ucraniano",
  wln: "valón",
  vie: "vietnamita",
  yid: "yidis (ídish)",
  yor: "yoruba",
}

export const getLanguageValue = (key: string) => languages[key];