import React from "react";

import Modal from "../../atoms/modal/modal";
import Button from "../../atoms/button/button";

import "./simpleModal.scss";

interface SimpleModalProps {
  open: boolean;
  onClose: Function;
  title?: string;
  imageUrl?: string;
  description: string;
  btnText: string;
  onBtnClick?: () => any | Function;
}

const SimpleModal = (props: SimpleModalProps) => {
  const { open, onClose, title, imageUrl, description, btnText, onBtnClick } = props;

  return (
    <Modal open={open} onClose={() => onClose()} className="m-simple-modal">
      <div className={`m-simple-modal__container
        ${title ? "" : "m-simple-modal__container__no-title"}`}
      >
        <button 
          className="icon-close m-simple-modal__container__icon" 
          onClick={() => onClose()} 
        />
        <div className="m-simple-modal__container__content">
          {title &&
            <h3 className="m-simple-modal__container__content__title">
              {title}
            </h3>
          }
          {imageUrl &&
            <img 
              className="m-simple-modal__container__content__image" 
              src={imageUrl} 
              alt={title ? `Imagen ${title}` : "Imagen modal"}
            />
          }
          <p className={`m-simple-modal__container__content__desc
            ${title ? "" : "m-simple-modal__container__no-title__desc"}`}
          >
            {description || ""}
          </p>
          <Button 
            type="button"
            classname="secondary"
            text={btnText}
            isDisabled={false}
            onClickAction={onBtnClick || undefined}
          />
        </div>
      </div>
    </Modal>
  )
}

export default SimpleModal;