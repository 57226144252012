import React, { useState, useEffect } from "react";

import useBrowserMode from "../../../shared/hooks/useBrowserMode";
import ShareModal from "../../molecules/share-modal/shareModal";
import Button from "../../atoms/button/button";
import useScrollDetect from "../../../shared/hooks/useScrollDetect";
import { normalizeTwoWords } from "../../../shared/utils/stringUtilities";
import useMarketingTags from "../../../shared/services/useMarketingTags";
import DefaultImage from "../../../assets/images/defaultLibro.jpg";

import "./headboardBookDetail.scss";

interface HeadboardBookDetailInterface {
  author: string;
  image: string;
  title: string;
  fragment: any;
  courceContent: string;
  typeResource: string;
  mmsId: string;
}

const HeadboardBookDetail = (props: HeadboardBookDetailInterface) => {
  const {
    author,
    image,
    title,
    fragment,
    courceContent,
    typeResource,
    mmsId,
  } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const { isScrollActive, refElement } = useScrollDetect();
  const { isBrowser, window, document } = useBrowserMode();
  const [scrollPercent, setScrollPercent] = useState(0);
  const [ellipsisModal, setellipsisModal] = useState(false);
  const [ellipsisModalMobile, setellipsisModalMobile] = useState(false);
  const [modalMobile, setmodalMobile] = useState(false);
  const [imgError, setimgError] = useState(false);
  const { sendInfo } = useMarketingTags();

  useEffect(() => {
    isBrowser && document.addEventListener("scroll", onScrollAction);

    return () => {
      isBrowser && document.removeEventListener("scroll", onScrollAction);
    };
  }, []);

  useEffect(() => {
    if (ellipsisModalMobile && modalMobile) {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    } else {
      document.getElementsByTagName("body")[0].style.overflow = "visible";
    }
  }, [ellipsisModalMobile]);

  const toggleModal = () => {
    if (!isModalVisible) {
      sendInfo("PROD_SHARED", window.location.href, [], [], mmsId);
    }
    setIsModalVisible(!isModalVisible);
  };

  let timerScroll: any;
  const onScrollAction = () => {
    window.clearTimeout(timerScroll);
    timerScroll = setTimeout(() => {
      let windowScroll = window.pageYOffset;
      let contentHeight = document.body.offsetHeight - 1000;
      setScrollPercent((windowScroll * 100) / contentHeight);
    }, 66);
  };

  const handleScroll = (option_modal) => {
    if (document.getElementById("conseguirlibro")) {
      sendInfo("PROD_GET", window.location.href, [], [], mmsId, courceContent);
      if (option_modal === "modal") {
        setellipsisModalMobile(!ellipsisModalMobile);
        setellipsisModal(!ellipsisModal);
        setmodalMobile(!modalMobile);
        var topS: any = document.getElementById("conseguirlibro").offsetTop;
        window.scrollTo(0, topS);
      } else {
        var topS1: any = document.getElementById("conseguirlibro").offsetTop;
        window.scrollTo(0, topS1);
      }
    }
  };

  const handleErroImage = () => {
    setimgError(true);
  };

  return (
    <>
      <div className="o-headboardbookdetail" ref={refElement}>
        <div className="o-headboardbookdetail__content">
          <div className="o-headboardbookdetail__content__img">
            <img
              src={(image && (imgError ? DefaultImage : image)) || DefaultImage}
              alt={title || "Imagen detalle libro"}
              onError={handleErroImage}
            />
            {typeResource === "audiobook" && (
              <div className="o-headboardbookdetail__content__img-type">
                <div className="content-icon">
                  <i className={"icon icon-headphones"} />
                </div>
              </div>
            )}
            {typeResource === "book" && courceContent && (
              <div className="o-headboardbookdetail__content__img-type">
                {courceContent.includes("digital") && (
                  <div className="content-icon">
                    <i className={"icon icon-mobile-alt"} />
                  </div>
                )}
                {courceContent.includes("physical") && (
                  <div className="content-icon">
                    <i className={"icon icon-book"} />
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="o-headboardbookdetail__content_detail">
            <h1
              className={
                "o-headboardbookdetail__content_detail__title" +
                (title.length > 29 ? " title_much" : "")
              }
            >
              {title}
            </h1>
            <p className="o-headboardbookdetail__content_detail__author">
              {author}
            </p>
            <i
              className={`icon icon-share gtmDetalleLibro${normalizeTwoWords(
                title
              )}Compartir`}
              onClick={toggleModal}
            >
              <span
                className={`hide-on-mobile gtmDetalleLibro${normalizeTwoWords(
                  title
                )}Compartir`}
              >
                Compartir
              </span>
            </i>
            <div className="o-headboardbookdetail__content_detail_btn">
              {courceContent.includes("digital") && fragment && (
                <Button
                  type={"link"}
                  classname={`tertiary gtmDetalleLibroClicLibro${
                    typeResource === "audiobook" ? "Escuchar" : "Leer"
                  }Fragmento${normalizeTwoWords(title)}`}
                  text={
                    typeResource === "audiobook" ? "ESCUCHAR UN FRAGMENTO" : "LEER UN FRAGMENTO"
                  }
                  href={fragment}
                  isDisabled={false}
                  target={"_self"}
                />
              )}
              <Button
                type={"button"}
                classname={`secondary`}
                text={typeResource === "audiobook" ? "CONSEGUIR AUDIOLIBRO" : 
                typeResource === "book" ? "CONSEGUIR LIBRO" : typeResource === "journal" ? "CONSEGUIR REVISTA" : typeResource === "film" ? "CONSEGUIR PELICULA" : "CONSEGUIR LIBRO"}
                onClickAction={() => handleScroll("")}
                isDisabled={false}
              />
            </div>
          </div>
        </div>

        {isScrollActive && (
          <div
            className={`o-headboardbookdetail-btns__tabs ${
              isScrollActive ? "o-headboardbookdetail-btns__tabs--fixed" : ""
            }`}
          >
            <div className="o-headboardbookdetail-btns__tabs-img hide-on-mobile">
              <img
                alt={title || "Imagen detalle libro"}
                src={
                  (image && (imgError ? DefaultImage : image)) || DefaultImage
                }
                onError={handleErroImage}
              />
            </div>
            <div
              className={`o-headboardbookdetail-btns__tabs-title ${
                isScrollActive
                  ? "o-headboardbookdetail-btns__tabs-title--shown"
                  : ""
              }`}
            >
              <span> {title} </span>
            </div>
            <ul className="o-headboardbookdetail-btns__tabs-btns">
              <li>
                {courceContent.includes("digital") && fragment && (
                  <Button
                    type={"link"}
                    classname={`tertiary hide-on-mobile gtmDetalleLibroClicLibro${
                      typeResource === "audiobook" ? "Escuchar" : "Leer"
                    }Fragmento${normalizeTwoWords(title)}`}
                    text={
                      typeResource === "audiobook"
                        ? "ESCUCHAR UN FRAGMENTO"
                        : "LEER UN FRAGMENTO"
                    }
                    href={fragment}
                    isDisabled={false}
                    target={"_self"}
                  />
                )}
              </li>
              <li>
                <Button
                  type={"button"}
                  classname={`secondary ${
                    isScrollActive ? "btn__secondary_size" : ""
                  }`}
                  text={typeResource === "audiobook" ? "CONSEGUIR AUDIOLIBRO" : 
                  typeResource === "book" ? "CONSEGUIR LIBRO" : typeResource === "journal" ? "CONSEGUIR REVISTA" : typeResource === "film" ? "CONSEGUIR PELICULA" : "CONSEGUIR LIBRO"}
                  onClickAction={() => handleScroll("")}
                  isDisabled={false}
                />
              </li>
              <li>
                <div className="ellipsis-header">
                  <div
                    className={
                      "ellipsis-header-content" +
                      (ellipsisModal ? " active_ellipsis" : "")
                    }
                    onClick={() => {
                      setellipsisModal(!ellipsisModal);
                      setellipsisModalMobile(!ellipsisModalMobile);
                    }}
                  >
                    <i className="icon icon-ellipsis-v" />
                  </div>
                  {ellipsisModal && (
                    <div className="ellipsis_open hide-on-mobile">
                      <div className="ellipsis_open__content">
                        <i
                          className={`icon icon-share gtmDetalleLibro${normalizeTwoWords(
                            title
                          )}Compartir`}
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleModal();
                          }}
                        >
                          <span
                            className={`hide-on-mobile gtmDetalleLibro${normalizeTwoWords(
                              title
                            )}Compartir`}
                          >
                            Compartir
                          </span>
                        </i>
                      </div>
                    </div>
                  )}
                </div>
              </li>
            </ul>
            <div className={`o-headboardbookdetail-btns__tabs-scrollbar`}>
              <span style={{ width: `${scrollPercent}%` }}></span>
            </div>
          </div>
        )}
        {ellipsisModal && isScrollActive && (
          <div className="ellipsis_scroll_open show-on-mobile">
            <div className="ellipsis_scroll_open_content">
              <div className="ellipsis_scroll_open_content_options">
                <div className="ellipsis_scroll_open_content_options_share">
                  <i
                    className={`icon icon-share gtmDetalleLibro${normalizeTwoWords(
                      title
                    )}Compartir`}
                    onClick={(e) => {
                      setellipsisModalMobile(!ellipsisModalMobile);
                      setellipsisModal(!ellipsisModal);
                      setmodalMobile(!modalMobile);
                      toggleModal();
                      e.stopPropagation();
                    }}
                  >
                    <span
                      className={`gtmDetalleLibro${normalizeTwoWords(
                        title
                      )}Compartir`}
                    >
                      Compartir
                    </span>
                  </i>
                </div>
                {courceContent.includes("digital") && fragment && (
                  <div className="ellipsis_scroll_open_content_options_share">
                    <i
                      className={`icon icon-read gtmDetalleLibroClicLibro${
                        typeResource === "audiobook" ? "Escuchar" : "Leer"
                      }Fragmento${normalizeTwoWords(title)}`}
                      onClick={(e) => {
                        window.location.assign(fragment);
                        setellipsisModalMobile(!ellipsisModalMobile);
                        setellipsisModal(!ellipsisModal);
                        setmodalMobile(!modalMobile);
                        e.stopPropagation();
                      }}
                    >
                      <span
                        className={`gtmDetalleLibroClicLibro${
                          typeResource === "audiobook" ? "Escuchar" : "Leer"
                        }Fragmento${normalizeTwoWords(title)}`}
                      >
                        {typeResource === "audiobook"
                          ? "Escuchar un fragmento"
                          : "Leer un fragmento"}
                      </span>
                    </i>
                  </div>
                )}
              </div>
              <span className="ellipsis_scroll_open_content_separated" />
              <div className="ellipsis_scroll_open_content_info">
                <span>{title}</span>
                <div className="ellipsis_scroll_open_content_info__btn">
                  <Button
                    type={"button"}
                    classname={`secondary ${
                      isScrollActive ? "btn__secondary_size" : ""
                    }`}
                    text={typeResource === "audiobook" ? "CONSEGUIR AUDIOLIBRO" : 
                    typeResource === "book" ? "CONSEGUIR LIBRO" : typeResource === "journal" ? "CONSEGUIR REVISTA" : typeResource === "film" ? "CONSEGUIR PELÍCULA" : "CONSEGUIR LIBRO"}
                    onClickAction={() => handleScroll("modal")}
                    isDisabled={false}
                  />
                  <div
                    className={
                      "ellipsis_scroll_open_content_info__btn_active_ellipsis"
                    }
                    onClick={() => {
                      setellipsisModal(!ellipsisModal);
                      setellipsisModalMobile(!ellipsisModalMobile);
                    }}
                  >
                    <i className="icon icon-ellipsis-v" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <ShareModal open={isModalVisible} onClose={toggleModal} />
      </div>
      <span className="o-headboardbookdetail__separator" />
    </>
  );
};

export default HeadboardBookDetail;
